import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import Executive from "./pages/Executive";
import About from "./pages/About";
import AllServices from "./pages/Allservices";
import Acupuncture from "./pages/Acupuncture";
import Physiotherapy from "./pages/Physiotherapy";
import Cupping from "./pages/Cupping";
import Moxibustion from "./pages/Moxibustion";
import Acupressure from "./pages/Acupressure";
import WhatsApp from "./components/WhatsApp";

function App() {
  return (
    <div>
      <Navbar />
      <div className=" overflow-x-hidden overflow-y-hidden    md:py-36 py-24 ">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/executive" element={<Executive />} />
          <Route path="/allServices" element={<AllServices />} />
          <Route path="/acupuncture" element={<Acupuncture />} />
          <Route path="/physiotherapy" element={<Physiotherapy />} />
          <Route path="/cupping" element={<Cupping />} />
          <Route path="/moxibustion" element={<Moxibustion />} />
          <Route path="/acupressure" element={<Acupressure />} />
        </Routes>
      </div>
      <WhatsApp />
      <Footer />
      <Toaster />
    </div>
  );
}

export default App;
