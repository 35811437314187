import React from "react";
import digital from "../assets/iconPhysio.jpg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Physiotherapy() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            10:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 rounded-md w-full object-cover cursor-pointer "
            src={digital}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Physiotherapy
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            Physiotherapy, also known as physical therapy, is a healthcare
            discipline dedicated to enhancing and restoring functional mobility
            and quality of life through physical intervention. It involves the
            assessment, diagnosis, and treatment of various musculoskeletal,
            neurological, and respiratory conditions. Physiotherapists use a
            range of techniques including exercises, manual therapy, and
            modalities like heat and cold therapy to address pain, improve
            movement, and prevent future injuries. By focusing on personalized
            treatment plans, physiotherapy aims to empower patients to regain
            independence, enhance their physical abilities, and achieve optimal
            health outcomes. It plays a crucial role in rehabilitation after
            surgery or injury, managing chronic conditions, and improving
            overall well-being.
          </span>

          <Link
            to="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
