import React from "react";
import digital from "../assets/iconacu.jpg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Acupuncture() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            10:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 rounded-md w-full object-cover cursor-pointer "
            src={digital}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Acupuncture
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            Acupuncture is a traditional Chinese medicine practice that involves
            inserting thin, sterile needles into specific points on the body to
            stimulate and balance the flow of Qi (energy). The goal is to
            restore harmony within the body's energy systems and promote natural
            healing. Acupuncture is used to address a variety of conditions,
            including chronic pain, stress, headaches, digestive disorders, and
            more. It is based on the concept that disruptions in the flow of Qi
            can lead to illness, and by targeting specific points, acupuncture
            aims to restore balance and improve overall well-being. The practice
            is often combined with other therapies, such as herbal medicine or
            moxibustion, to enhance its effectiveness. Despite its ancient
            origins, acupuncture is recognized and utilized in modern medicine,
            with many studies supporting its efficacy for certain conditions.
          </span>

          <Link
            to="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
