import React, { useState } from "react";
import n1 from "../assets/acu6.jpg";
import n2 from "../assets/acu2.jpg";
import n3 from "../assets/acu4.jpg";
import n4 from "../assets/acu3.jpg";
import n5 from "../assets/acu5.jpg";
import n6 from "../assets/acu1.jpg";
import n7 from "../assets/acu7.jpg";
import n8 from "../assets/acu8.jpg";
import n9 from "../assets/acu9.jpg";
import g1 from "../assets/g1.jpg";
import g2 from "../assets/g2.jpg";
import g3 from "../assets/g3.jpg";
import g4 from "../assets/g4.jpg";
import g5 from "../assets/g5.jpg";
import g6 from "../assets/g6.jpg";

export default function Gallery() {
  const images = [
    { src: n1, alt: "Louvre" },
    { src: n2, alt: "Louvre" },
    { src: n3, alt: "Louvre" },
    { src: n4, alt: "Louvre" },
    { src: n5, alt: "Louvre" },
    { src: n6, alt: "Louvre" },
    { src: n7, alt: "Louvre" },
    { src: n8, alt: "Louvre" },
    { src: n9, alt: "Louvre" },
    { src: g1, alt: "Louvre" },
    { src: g2, alt: "Louvre" },
    { src: g3, alt: "Louvre" },
    { src: g4, alt: "Louvre" },
    { src: g5, alt: "Louvre" },
    { src: g6, alt: "Louvre" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const showPrevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
  };

  const showNextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? images.length - 1 : prevIndex + 1
    );
  };

  return (
    <div className="md:px-0 px-4 flex justify-center items-center">
      <div className="md:w-10/12 w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10">
          {images.map((image, index) => (
            <div key={index} className="mb-4">
              <div className="relative overflow-hidden bg-cover bg-no-repeat h-96 w-96">
                <img
                  src={image.src}
                  className="w-full   hover:translate-y-px duration-300 h-full object-cover rounded-md cursor-pointer"
                  alt={image.alt}
                  onClick={() => openModal(index)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90 p-4">
          <button
            className="absolute top-4 right-4 text-white text-4xl"
            onClick={closeModal}
          >
            &times;
          </button>
          <button
            className={`absolute left-4 text-white text-3xl ${
              currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={showPrevImage}
            disabled={currentIndex === 0}
          >
            &#9664;
          </button>
          <div className="flex justify-center items-center w-[85vw] md:h-[70vh] h-[50vh]">
            <img
              src={images[currentIndex].src}
              alt={images[currentIndex].alt}
              className="w-full h-full object-cover"
            />
          </div>
          <button
            className={`absolute right-4 text-white text-3xl ${
              currentIndex === images.length - 1
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            onClick={showNextImage}
            disabled={currentIndex === images.length - 1}
          >
            &#9654;
          </button>
        </div>
      )}
    </div>
  );
}
