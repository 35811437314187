import React, { useState, useEffect } from "react";
import icon1 from "../assets/slider.webp";
import icon2 from "../assets/slider2.webp";
import icon3 from "../assets/slider3.webp";
import icon4 from "../assets/slider4.webp";
import acu1 from "../assets/acu1.jpg";
import acu2 from "../assets/acu2.jpg";
import acu3 from "../assets/acu3.jpg";
import acu4 from "../assets/acu4.jpg";

export default function WhyChoose() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [acu1, acu2, acu3, acu4]; // Array of left images

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div className="flex justify-center items-center bg-primary-400 md:h-[68vh] h-full">
      <div className="md:px-0 px-4 md:py-0 py-11 md:flex justify-between gap-16 items-center md:w-10/12 w-full">
        <div className="md:flex-1">
          <img
            className="md:h-[68vh] md:rounded-none rounded-md w-full h-full md:pb-0 pb-5 object-cover cursor-pointer"
            src={images[currentImageIndex]} // Automatically changing image
            alt="img"
          />
        </div>
        <div className="md:flex-1 flex flex-col gap-8">
          <div className="cursor-pointer text-gray-100 md:text-4xl text-3xl font-semibold tracking-widest">
            Why Choose Us ?
          </div>
          <div className="text-gray-200 md:text-[19px] text-[16px]">
            KATHMANDU ACUPUNCTURE & PHYSIOTHERAPY CLINIC PVT. LTD. founded in
            2060, offers expert, personalized care in a serene environment. With
            experienced practitioners and a focus on holistic healing, KAAPC is
            a trusted choice for acupuncture and acupressure services in
            Kathmandu.
          </div>
          <div className="grid md:grid-cols-2 md:gap-12 gap-8">
            {icons.map((icon, index) => (
              <div
                key={index}
                className="cursor-pointer group flex items-center gap-2"
              >
                <img
                  className="object-cover cursor-pointer rounded-full md:h-10 md:w-10 h-12 w-12"
                  src={icon.img}
                  alt={icon.name}
                />
                <span className="text-gray-100 md:text-xl text-xl group-hover:text-gray-300 duration-200">
                  {icon.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const icons = [
  {
    img: icon1,
    name: "अनुभवी टिम",
  },
  {
    img: icon2,
    name: "गुणस्तरीय सेवा",
  },
  {
    img: icon3,
    name: "व्यक्तिगत परामर्श",
  },
  {
    img: icon4,
    name: "नतिजा केन्द्रित",
  },
];
