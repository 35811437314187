import React from "react";
import n5 from "../assets/acu4.jpg";

export default function About() {
  return (
    <div className="bg-white flex  md:px-0 px-4 justify-center">
      <div className="flex justify-center w-full md:w-10/12">
        <div className="grid gap-8 md:grid-cols-2 lg:gap-12">
          <div className="lg:flex lg:flex-col">
            <p className=" text-primary-350 text-xl mb-4">
              परिचय , काठमाडौं अकुपंचर तथा अकुप्रेसर सेवा केन्द्र{" "}
            </p>
            <p className="mb-6 text-gray-600   md:mb-8">
              तथा मोक्सिबुसन चिकित्सा प्रणाली रोगहरूको रोकथाम र उपचारको लागि
              प्रयोग गरिने चिनियाँ औषधि विज्ञानको एउटा धेरै महत्वपूर्ण भाग हो ।
              आजभन्दा करिब ५ हजार वर्ष पहिले चीनमा शुरु भएको यो चिकित्सा प्रणाली
              आज विश्वका विकसित र विकासोन्मुख सबै मुलुक हरूमा अत्यन्त सफलताका
              साथ सञ्चालन भइराखेको छ। असल उपचार, शरीरलाई कुनै प्रतिकूल असर (साइड
              इफेक्ट) नहुनु, घेरे रोगहरूमा प्रयोग गर्न सकिने आदि गुणहरूले गर्दा
              यो चिकित्सा पद्धति आज विश्वभरि नै लोकप्रिय भइराखेको छ । मान्यता
              विश्व स्वास्थ्य संगठन (WHO) द्वारा प्राप्त अकुपंचर, अकुप्रेसर तथा
              मोक्सिबुसन चिकित्सा प्रणालीद्वारा दुइसयभन्दा बढी रोगहरूको सफल
              उपचार हुन्छ । आधुनिक चिकित्सा विज्ञान अति विकसित भएको वर्तमान
              अवस्थामा यसबाट समेत उपचार गर्न सम्भव नभएका कैयन रोगहरू अकुपंचर तथा
              मोक्सिबुसन चिकित्सा प्रणालीद्वारा सफलतापूर्वक उपचार गर्न सकिन्छ ।
              यस पद्धतिद्वारा उपचार गर्न सकिने मुख्य रोगहरू मध्ये केही निम्न
              प्रकारका छन् । १) नशासम्बन्धी रोगहरू जस्तै- पक्षघात, मुख,
              बाङ्गिने, नशासम्बन्धी विभिन्न दुखाईहरू, टाउको दुख्ने (माइग्रेन),
              छारे रोग, पार्किन्सन रोग, निन्द्रा कम लाग्ने वा लाग्दै नलाग्ने,
              रिंगटा लाग्ने, मांसपेशी सुक्ने, नशासम्बन्धी गडबडीहरू आदि । २)
              हाडजोर्नी सम्बन्धी रोगहरू जस्तै विभिन्न किसिमका बाथका रोगहरू,
              बंगरा, गर्धन, पाता, ढाड (कम्मर दुख्ने, कम्मरदेखि खुट्टासम्मको नशा
              दुख्ने रोग (सायटिका), हाड खिइने, बढ्ने कारणबाट हुने समस्याहरू,
              विभिन्न किसिमका नयाँ तथा पुराना दुखाइहरू आदि । ३) श्वास-प्रश्वास
              सम्बन्धी रोगहरू जस्तै- रुघाखोकी, दम-धम्की, ब्रोङ्काइटिस आदि । ४)
              पाचन प्रणाली सम्बन्धी रोगहरू जस्तै- ग्याष्ट्रिक रोग, दाँत दुख्ने,
              बाकी लगातार र लामो समयसम्म लागिरहने, वाकवाकी लाग्ने, अपच, कब्जियत,
              झाडा पखाला आदि । ५) मुत्र प्रणाली सम्बन्धी रोगहरू जस्तै- राती
              ओछ्यानमा पिसाब फेर्ने, पिसाव बन्द हुने । ६) स्त्री रोग तथा प्रसुति
              सम्बन्धी रोगहरू जस्तै- अनियमित र पिडाग्रस्त महिनाबारी, महिनावारी
              रोकिनु, स्वेत प्रदर, महिनावारी बन्द भएपछि देखिने समस्याहरू, गर्भको
              बच्चा ठीक ठाउँमा नहुनु, असैह्य प्रसव पीडा आदि ।
            </p>
          </div>

          <div>
            <div className="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto">
              <img
                src={n5}
                alt="img"
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>

          <div className="md:col-span-2">
            <h2 className=" text-primary-350 text-xl mb-4">
              काठमाडौं अकुपंचर एण्ड फिजियोथेरापी क्लिनिक स्थापनाको उद्देश्य:
            </h2>

            <p className=" text-gray-600  ">
              हाल नेपालमा पनि यस चिकित्सा पद्धतिको प्रयोग बढ्दै गइराखेको छ ।
              विभिन्न निजी क्लिनिकहरूले यसको सेवा प्रदान गर्दै आइरहेता पनि
              दक्षता र स्तरीयताको सम्बन्धमा निश्चित रुपमा ध्यान पुग्नु अनिवार्य
              हुन्छ । शरीरको स्वास्थ्य विज्ञान (Physiology) र शरीर विज्ञान (
              Anatomy) तथा सम्बन्धित विषयमा राम्रो ज्ञान नभएका व्यक्तिहरूबाट
              उपचार गराउँदा डरलाग्दो दुर्घटनाहरू पनि हुने गरेका छन् । तसर्थ
              विरामीले उपचार गराउँदा दक्ष चिकित्सकद्वारा मात्र उपचार गराउनु
              स्वयंका लागि सुरक्षित तथा लाभदायक हुनुका अलावा अदक्ष व्यक्तिबाट
              हुने दुरुपयोगलाई समेत रोक्न प्रत्यक्ष सहयोग पुग्न जान्छ । यस
              क्लिनिक स्थापनाको मुख्य उद्देश्य अकुपंचर, अकुप्रेसर तथा
              मोक्सिबुसनको क्षेत्रमा स्तरीय सेवा प्रदान गर्नु हो जुन वर्तमान
              परिप्रेक्ष्यमा अति आवश्यक देखिएको छ । एक्काइसौ शताब्दीमा वैकल्पिक
              चिकित्सा पद्दतिले लोकप्रियता पाउँदै गएको वर्तमान परिप्रेक्ष्यमा
              संसारभरिका प्रशिद्ध विश्वविद्यालय, अस्पताल तथा अनुसन्धान
              केन्द्रहरूद्वारा समेत वैज्ञानिक अनुसन्धान, अध्ययन तथा उपचारमा
              प्रयोग हुँदै आइरहेको, कुनै पनि प्रतिकुल असर (साइड इफेक्ट) नभएको
              सुरक्षित तथा भरपर्दो अकुपंचर, अकुप्रेसर तथा मोक्सिबुसन चिकित्सा
              पद्दतिबाट आम जनताको स्वास्थ्य रक्षामा महत्वपूर्ण भूमिका हुने
              कुरामा दुइमत हुन सक्दैन ।
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
