import React from "react";

export default function Faq() {
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1500"
      className=" flex  overflow-y-hidden  md:px-0 px-4 justify-center items-center"
    >
      <div className=" md:flex md:w-10/12 md:shadow-lg rounded-md md:pb-10  justify-between w-full">
        <div className=" md:px-20  md:pt-0 pt-12  w-full">
          <div className="space-y-4">
            <p className=" flex md:justify-center items-center  text-primary-350 text-2xl md:text-3xl font-semibold mb-10 ">
              Frequently Asked Questions ?
            </p>
            <details className="group  rounded-t-2xl  bg-primary-400 hover:bg-primary-450 duration-300 p-6 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-100">
                  What services does Kathmandu Acupuncture & Physiotherapy
                  Clinic offer?
                </h2>

                <span className="shrink-0 rounded-full  bg-gray-200 p-1.5 text-gray-900 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-300">
                We provide acupuncture, physiotherapy, acupressure, and pain
                management treatments, focusing on natural and holistic healing
                approaches.
              </p>
            </details>
            <details className="group rounded-t-2xl  bg-primary-400 hover:bg-primary-450 duration-300  p-6 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-100">
                  What conditions can be treated here?
                </h2>

                <span className="shrink-0 rounded-full  bg-gray-200 p-1.5 text-gray-900 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-300">
                We treat back pain, arthritis, sciatica, migraines, sports
                injuries, and more, aiming to reduce pain and improve mobility.
              </p>
            </details>
            <details className="group rounded-t-2xl  bg-primary-400 hover:bg-primary-450 duration-300  p-6 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-100">
                  What happens during the first visit?
                </h2>

                <span className="shrink-0 rounded-full  bg-gray-200 p-1.5 text-gray-900 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-300">
                You'll receive a thorough assessment, and we’ll create a
                personalized treatment plan based on your condition.
              </p>
            </details>

            <details className="group rounded-t-2xl  bg-primary-400 hover:bg-primary-450 duration-300  p-6 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-100">
                  Where is Kathmandu Acupuncture & Physiotherapy Clinic located?
                </h2>

                <span className="shrink-0 rounded-full  bg-gray-200 p-1.5 text-gray-900 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-300">
                The clinic is located at Balkhu, Kuleshwor Marg, Kathmandu.
              </p>
            </details>
          </div>
        </div>
      </div>
    </div>
  );
}
