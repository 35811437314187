import React, { useEffect, useState } from "react";
import logo1 from "../assets/logo.png";
import { Link, useLocation } from "react-router-dom";

import crown from "../assets/iconPhysio.jpg";
import denatlemr from "../assets/iconmixo.jpg";
import implant from "../assets/iconcupping.jpg";
import dental from "../assets/iconpress.jpg";
import digital from "../assets/iconacu.jpg";
import oral from "../assets/slider5.webp";

export default function Navabar() {
  const [state, setState] = useState(false);
  const [drapdownState, setDrapdownState] = useState({
    isActive: false,
    idx: null,
  });

  const location = useLocation();
  console.log(location.pathname);

  const navigation = [
    { title: "Home", path: "/", isDrapdown: false },
    {
      title: "Our Services ",
      path: "/allServices",
      isDrapdown: true,
      navs: dropdownNavs,
    },
    { title: "About Us", path: "/about", isDrapdown: false },
    { title: "Executive Comittee", path: "/executive", isDrapdown: false },
    { title: "Gallery", path: "/gallery", isDrapdown: false },
    { title: "Contact Us", path: "/contact", isDrapdown: false },
  ];

  useEffect(() => {
    document.onclick = (e) => {
      const target = e.target;
      if (!target.closest(".nav-menu"))
        setDrapdownState({ isActive: false, idx: null });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setState(false); // Close the mobile menu on navigation
  }, [location.pathname]);

  return (
    <div className="z-50  fixed left-0 right-0 top-0">
      <>
        <nav
          className={`relative z-40 bg-gray-100 w-full md:static md:text-sm md:border-none ${
            state ? "shadow-lg rounded-b-xl md:shadow-none" : ""
          }`}
        >
          <div className="items-center  py-1 gap-x-14 px-4 max-w-screen-xl mx-auto md:flex  md:px-0">
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <Link to="/" className=" flex  items-center gap-2  ">
                <div>
                  <img
                    src={logo1}
                    className=" h-12 object-cover cursor-pointer"
                    alt=" logo"
                  />
                </div>
                <p className=" text-primary-450 flex justify-center items-center text-[14px] font-serif font-semibold">
                काठमाडौँ अकुपन्चर एण्ड फिजियोथेरापी
                </p>
              </Link>

              <div className="md:hidden">
                <button
                  className="text-primary-400  cursor-pointer font-semibold"
                  onClick={() => setState(!state)}
                >
                  {state ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-8 h-8   text-primary-400  cursor-pointer font-semibold"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            <div
              className={`nav-menu flex-1 pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                state ? "block" : "hidden"
              }`}
            >
              <ul className="items-center justify-end space-y-6 md:flex md:space-x-6 md:space-y-0">
                {navigation.map((item, idx) => {
                  return (
                    <li key={idx}>
                      {item.isDrapdown ? (
                        <button
                          className="w-full font-serif flex items-center md:justify-between gap-1 text-gray-600 md:font-semibold   hover:text-primary-400 md:text-[18px] text-[17px]"
                          onClick={() =>
                            setDrapdownState({
                              idx,
                              isActive: !drapdownState.isActive,
                            })
                          }
                        >
                          {item.title}
                          {drapdownState.idx === idx &&
                          drapdownState.isActive ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                fillRule="evenodd"
                                d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-6 h-6 "
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                        </button>
                      ) : (
                        <Link
                          className={`${
                            location.pathname === item.path
                              ? " text-primary-400  font-semibold "
                              : ""
                          }     block text-gray-600 md:font-semibold   font-serif hover:text-primary-400 md:text-[18px] text-[17px]`}
                          to={item.path}
                        >
                          {item.title}
                        </Link>
                      )}
                      {item.isDrapdown &&
                      drapdownState.idx === idx &&
                      drapdownState.isActive ? (
                        <div className=" md:px-0 px-4 bg-primary-500 md:overflow-hidden   overflow-y-scroll md:h-max  h-[60vh] mt-6 inset-x-0 top-20 w-full md:absolute md:border-y md:shadow-md md:mt-0">
                          <ul className="max-w-screen-xl mx-auto grid items-center gap-6  md:grid-cols-2 lg:grid-cols-3 md:py-10 py-6">
                            {item?.navs.map((dropdownItem, idx) => (
                              <li key={idx}>
                                {/* <p className="text-indigo-600 text-sm">
                                  {dropdownItem.label}
                                </p> */}
                                <ul className="mt-5 space-y-6  hover:scale-105 duration-500">
                                  {dropdownItem.navs.map((navItem, idx) => (
                                    <li key={idx} className="group">
                                      <Link
                                        onClick={() => setState(!state)}
                                        to={navItem.path}
                                        className="flex gap-3 items-center"
                                      >
                                        <img
                                          className="w-12 h-12 rounded-full border-4  border-gray-200 bg-indigo-50  text-primary-300 flex items-center justify-center   duration-500 group-hover:text-white md:w-14 md:h-14"
                                          src={navItem.icon}
                                          alt=""
                                        />
                                        <div>
                                          <span className="   text-gray-100  duration-200  text-[17px]  md:text-[18px]">
                                            {navItem.title}
                                          </span>
                                          <p className=" text-[15px] text-gray-300 group-hover:text-gray-400 mt-1">
                                            {navItem.desc}
                                          </p>
                                        </div>
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </nav>
        {state ? (
          <div
            className="z-10 fixed top-0 w-screen h-screen bg-black/20 backdrop-blur-sm md:hidden"
            onClick={() => setState(false)}
          ></div>
        ) : (
          ""
        )}
      </>
    </div>
  );
}

const dropdownNavs = [
  {
    label: "Products",
    navs: [
      {
        title: "All Our Services",
        desc: " All Our Services...",
        path: "/allServices",
        icon: oral,
      },
    ],
  },
  {
    label: "Products",
    navs: [
      {
        title: "Acupuncture",
        desc: "Acupuncture is a traditional Chinese medicine practice that involves inserting ...",
        path: "/acupuncture",
        icon: digital,
      },
    ],
  },
  {
    label: "Resources",
    navs: [
      {
        title: "Acupressure",
        desc: "Acupressure is a therapeutic technique that involves applying manual...",
        path: "/acupressure",
        icon: dental,
      },
    ],
  },
  {
    label: "Company",
    navs: [
      {
        title: "Moxibustion",
        desc: "Moxibustion is a traditional Chinese medicine technique that involves ...",
        path: "/moxibustion",
        icon: implant,
      },
    ],
  },

  {
    label: "Company",
    navs: [
      {
        title: "Cupping ",
        desc: "Cupping therapy is an ancient healing practice that involves placing...",
        path: "/cupping",
        icon: denatlemr,
      },
    ],
  },
  {
    label: "Company",
    navs: [
      {
        title: "Physiotherapy",
        desc: "Physiotherapy, also known as physical therapy, is a healthcare ...",
        path: "/physiotherapy",
        icon: crown,
      },
    ],
  },
];
