import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import dent3 from "../assets/acu5.jpg";
import dental7 from "../assets/acu6.jpg";
import dental10 from "../assets/acu3.jpg";

export default function HeroSection() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:w-10/12  w-full flex  items-center   ">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 12500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {items.map((item, index) => (
            <SwiperSlide>
              <div
                key={index}
                className=" grid md:grid-cols-2 md:gap-20 gap-5  md:pb-12 pb-5 "
              >
                <div className=" md:flex-1 flex justify-start items-start  flex-col md:gap-8 gap-4">
                  <h1 className=" curve-underline  text-primary-450 md:text-3xl text-2xl font-semibold">
                    {item.name}
                  </h1>
                  <h2 className=" text-[17px] text-gray-500">{item.desc}</h2>
                  <div className="  w-full  grid md:grid-cols-3 md:gap-8 gap-7 md:mt-12 mt-4">
                    <button className="  w-full bg-primary-400 hover:bg-primary-450 duration-500 text-gray-100 px-4 py-2 text-xl rounded-md">
                      {item.btn}
                    </button>
                    <button className=" w-full  bg-primary-400 hover:bg-primary-450 duration-500 text-gray-100 px-4 py-2 text-xl rounded-md">
                      {item.btn1}
                    </button>
                    <button className=" w-full  bg-primary-400 hover:bg-primary-450 duration-500 text-gray-100 px-4 py-2 text-xl rounded-md">
                      {item.btn2}
                    </button>
                  </div>
                </div>
                <div className=" md:flex-1 cursor-pointer">
                  <img
                    className=" rounded-xl border border-gray-300  w-full h-96  cursor-pointer object-cover"
                    src={item.img}
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

const items = [
  {
    img: dent3,
    name: "Welcome To (KAAPC )",
    desc: "Welcome to  KATHMANDU ACUPUNCTURE & PHYSIO THERAPY CLINIC PVT. LTD. , established in 2060. We are committed to enhancing your well-being through expert acupuncture and acupressure services. Experience holistic care that helps you achieve balance and relieve pain.",
    btn: "01-5922799 ",
    btn1: "9763205520",
    btn2: "985-1060999",
  },
  {
    img: dental7,
    name: "Personalized Care",
    desc: "Personalized care tailors medical treatment to each individual's unique needs, focusing on their specific health history, genetics, and lifestyle. This approach enhances treatment effectiveness and patient satisfaction by providing care that is both effective and aligned with personal preferences.",
    btn: "01-5922799 ",
    btn1: "9763205520",
    btn2: "985-1060999",
  },
  {
    img: dental10,
    name: "Modern Facilities",
    desc: " Modern facilities are equipped with the latest technology and amenities to provide top-notch services and enhance user experience. These facilities prioritize efficiency, comfort, and safety, ensuring that users have access to cutting-edge resources in a well-designed environment.",
    btn: "01-5922799 ",
    btn1: "9763205520",
    btn2: "985-1060999",
  },
];
