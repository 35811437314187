import React from "react";
import { Link } from "react-router-dom";
import crown from "../assets/iconpress.jpg";
import denatlemr from "../assets/iconacu.jpg";
import implant from "../assets/iconmixo.jpg";
import dental from "../assets/iconcupping.jpg";
import digital from "../assets/iconPhysio.jpg";

export default function AllServices() {
  return (
    <div className="md:px-0 px-4 flex flex-col md:gap-10 gap-6 justify-center items-center">
      <h1 className="text-primary-450 md:text-2xl text-xl font-semibold underline">
        All Our Services
      </h1>
      <span className="md:px-36 md:text-[17px] text-[16px] text-gray-500">
      काठमाडौँ अकुपन्चर एण्ड फिजियोथेरापी क्लिनिक प्रा. लि. चिकित्सा प्रणाली रोगहरूको रोकथाम र
        उपचारको लागि प्रयोग गरिने चिनियाँ औषधि विज्ञानको एउटा धेरै महत्वपूर्ण
        भाग हो। आजभन्दा करिब ५ हजार वर्ष पहिले चीनमा शुरु भएको यो चिकित्सा
        प्रणाली आज विश्वका विकसित र विकासोन्मुख सबै मुलुकहरूमा अत्यन्त सफलताका
        साथ सञ्चालन भइराखेको छ। असल उपचार, शरीरलाई कुनै प्रतिकूल असर (साइड
        इफेक्ट) नहुनु, धेरै रोगहरूमा प्रयोग गर्न सकिने आदि गुणहरूले गर्दा यो
        चिकित्सा पद्धति आज विश्वभरि नै लोकप्रिय भइराखेको छ। विश्व स्वास्थ्य
        संगठन (WHO) द्वारा मान्यता प्राप्त अकुपंचर, अकुप्रेसर तथा मोक्सिबुसन
        चिकित्सा प्रणालीद्वारा दुइसयभन्दा बढी रोगहरूको सफल उपचार हुन्छ। आधुनिक
        चिकित्सा विज्ञान अति विकसित भएको वर्तमान अवस्थामा यसबाट समेत उपचार गर्न
        सम्भव नभएका कैयन रोगहरू अकुपंचर तथा मोक्सिबुसन चिकित्सा प्रणालीद्वारा
        सफलतापूर्वक उपचार गर्न सकिन्छ।
      </span>
      <div className="flex justify-center items-center md:w-10/12">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-14 gap-10">
          {services.map((service, index) => (
            <div
              key={index}
              className="rounded-md group bg-primary-450 hover:bg-primary-400 duration-500"
            >
              <img
                className="h-52 w-full cursor-pointer object-cover"
                src={service.img}
                alt={service.name}
              />
              <div className="flex flex-col gap-3 px-5 py-6">
                <p className="text-gray-100 md:text-2xl text-xl group-hover:text-gray-200 duration-200">
                  {service.name}
                </p>
                <span className="text-gray-300 md:text-[16px] text-[15px] group-hover:text-gray-300">
                  {service.desc}
                </span>
                <div className="flex justify-between">
                  <span></span>
                  <Link
                    to={service.path}
                    className="w-max bg-gray-100 px-4 py-1 rounded-md hover:bg-gray-200 duration-500"
                  >
                    {service.btn}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export const services = [
  {
    img: digital,
    name: "Physiotherapy",
    desc: "Physiotherapy, also known as physical therapy, is a healthcare......",
    btn: "View Details",
    path: "/physiotherapy",
  },
  {
    img: dental,
    name: "Cupping",
    desc: "Cupping therapy is an ancient healing practice that involves placing......",
    btn: "View Details",
    path: "/cupping",
  },
  {
    img: implant,
    name: "Moxibustion",
    desc: "Moxibustion is a traditional Chinese medicine technique that involves ......",
    btn: "View Details",
    path: "/moxibustion",
  },
  {
    img: denatlemr,
    name: "Acupuncture",
    desc: "Acupuncture is a traditional Chinese medicine practice that involves inserting ......",
    btn: "View Details",
    path: "/acupuncture",
  },
  {
    img: crown,
    name: "Acupressure",
    desc: "Acupressure is a therapeutic technique that involves applying manual .....",
    btn: "View Details",
    path: "/acupressure",
  },
];
