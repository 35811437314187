import React from "react";
import digital from "../assets/iconmixo.jpg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Moxibustion() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            10:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 rounded-md w-full object-cover cursor-pointer "
            src={digital}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Moxibustion
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            Moxibustion is a traditional Chinese medicine technique that
            involves burning moxa, a substance made from dried mugwort leaves,
            near or on specific points of the body. The heat generated from the
            burning moxa is believed to stimulate these points, enhance the flow
            of Qi (energy), and promote healing. Moxibustion can be applied
            directly on the skin or indirectly, with a layer of ginger or salt
            placed between the moxa and the skin to avoid direct contact. This
            therapy is used to address conditions such as pain, digestive
            issues, and menstrual problems, and is often combined with
            acupuncture for a holistic approach. It is valued for its warming
            effects and its ability to improve circulation and balance the
            body's energy.
          </span>

          <Link
            to="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
