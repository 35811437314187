import React from "react";
import { Link } from "react-router-dom";
import dental10 from "../assets/g1.jpg";

const Welcome = () => {
  return (
    <div className=" md:px-0  px-4 flex justify-center items-center">
      <div className=" md:w-10/12  w-full overflow-hidden rounded-xl border shadow-lg ">
        <div className="flex flex-col overflow-hidden bg-white sm:flex-row md:h-90">
          <div className="flex w-full flex-col p-4 sm:w-1/2 sm:p-8 lg:w-3/5">
            <h2 className="md:text-xl   font-semibold text-gray-800 ">
              काठमाडौँ अकुपन्चर
              <span className="  px-2 text-primary-400">
                एण्ड फिजियोथेरापी क्लिनिक प्रा. लि.
              </span>
              बल्खु, कुलेश्वर मार्ग, काठमाडौं
            </h2>

            <p className="mt-4 mb-8 max-w-md text-gray-500">
              Welcome KATHMANDU ACUPUNCTURE & PHYSIOTHERAPY CLINIC PVT. LTD.
              established in 2060. We are committed to enhancing your well-being
              through expert acupuncture and acupressure services. Experience
              holistic care that helps you achieve balance and relieve pain.
            </p>

            <Link
              to="/about"
              className="group mt-auto flex w-44 cursor-pointer select-none items-center justify-center rounded-md   bg-primary-400 px-6 py-2 text-white transition"
            >
              <span className="group text-sm flex w-full items-center justify-center rounded py-1 text-center font-bold">
                More About
              </span>
              <svg
                className="flex-0 group-hover:w-6 ml-4 h-6 w-0 transition-all"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </Link>
          </div>

          <div className="order-first ml-auto h-48 w-full bg-gray-700 sm:order-none sm:h-auto sm:w-1/2 lg:w-2/5">
            <img
              className="h-full w-full object-cover"
              src={dental10}
              loading="lazy"
              alt="Winter Collection"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
