import React from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  const services = [
    { name: "Physiotherapy", path: "/physiotherapy" },
    { name: "Cupping", path: "/cupping" },
    { name: "Moxibustion", path: "/moxibustion" },
    { name: "Acupuncture", path: "/acupuncture" },
    { name: "Acupressure", path: "/acupressure" },
  ];

  const helpfulLinks = [
    { name: "About Us", path: "/about" },
    { name: "Contact Us", path: "/contact" },
    { name: "Gallery", path: "/gallery" },
  ];

  return (
    <div className="overflow-y-hidden">
      <footer className="bg-primary-450 flex justify-center items-center">
        <div className="md:w-10/12 w-full md:px-0 px-4 space-y-10 py-10 lg:space-y-7">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <div className="flex items-center gap-2">
                <Link to="/">
                  <img
                    src={logo}
                    className="h-20 object-cover cursor-pointer"
                    alt="logo"
                  />
                </Link>
                <p className="text-white font-semibold">
                  KATHMANDU ACUPUNCTURE & PHYSIOTHERAPY CLINIC PVT. LTD
                </p>
              </div>
              <p className="mt-4 max-w-xs hover:opacity-75 text-gray-300">
                काठमाडौँ अकुपन्चर एण्ड फिजियोथेरापी क्लिनिक प्रा. लि. बल्खु,
                कुलेश्वर मार्ग, काठमाडौं
              </p>

              <ul className="mt-8 flex gap-7">
                {/* Social media icons go here */}
              </ul>
            </div>

            <div
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1500"
              className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-3"
            >
              <div>
                <p className="font-medium text-gray-200 md:text-2xl text-xl">
                  Our Services
                </p>

                <ul className="md:mt-6 mt-4 space-y-5 text-sm">
                  {services.map((service, index) => (
                    <li key={index}>
                      <Link
                        to={service.path}
                        className="text-gray-300 text-[17px] md:text-[18px] transition hover:opacity-75"
                      >
                        {service.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <p className="font-medium text-gray-200 md:text-2xl text-xl">
                  Helpful Links
                </p>

                <ul className="md:mt-6 mt-4 space-y-5 text-sm">
                  {helpfulLinks.map((link, index) => (
                    <li key={index}>
                      <Link
                        to={link.path}
                        className="text-gray-300 text-[17px] md:text-[18px] transition hover:opacity-75"
                      >
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <p className="font-medium text-gray-200 md:text-2xl text-xl">
                  24/7 Hours Supports
                </p>
                <ul className="md:mt-6 mt-4 space-y-5 text-sm">
                  <li>
                    <p className="text-gray-300 flex gap-2 text-[17px] md:text-[14px] transition hover:opacity-75">
                      {/* Email SVG icon */}
                      kathmanduacupuncture1@gmail.com
                    </p>
                  </li>
                  <li>
                    <p className="text-gray-300 flex gap-2 text-[17px] md:text-[18px] transition hover:opacity-75">
                      {/* Phone SVG icon */}
                      +977 1234567890
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
