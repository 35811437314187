import React from "react";
import HomeSlider from "../components/HomeSlider";
import Welcome from "../components/Welcome";
import Banner from "../components/Banner";
import WhyChoose from "../components/WhyChoose";
import OurServices from "../components/OurServices";
import Faq from "../components/Faq";

export default function Home() {
  return (
    <div className=" flex flex-col  md:gap-20 gap-16">
      <HomeSlider />
      <Banner />
      <Welcome />
      <Faq />
      <WhyChoose />
      <OurServices />
    </div>
  );
}
