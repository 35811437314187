import React from "react";
import digital from "../assets/iconcupping.jpg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Cupping() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            10:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 rounded-md w-full object-cover cursor-pointer "
            src={digital}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Cupping
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            Cupping therapy is an ancient healing practice that involves placing
            cups on the skin to create suction. This suction is believed to
            mobilize blood flow, promote healing, and reduce muscle tension. The
            therapy can be done using various methods, including dry cupping
            (where the cups are applied without any additional substances) and
            wet cupping (which involves a small amount of controlled
            bloodletting). Traditionally used in Chinese medicine, cupping is
            thought to help with conditions such as pain, inflammation, and poor
            circulation. Modern applications of cupping therapy often integrate
            it with other treatments, and its effectiveness is still the subject
            of ongoing research. Despite its historical roots, many people find
            cupping to be a beneficial complementary therapy for various health
            issues.
          </span>

          <Link
            to="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
