import React from "react";
import digital from "../assets/iconpress.jpg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Acupressure() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            10:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 rounded-md w-full object-cover cursor-pointer "
            src={digital}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Acupressure
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            Acupressure is a therapeutic technique that involves applying manual
            pressure to specific points on the body, known as acupoints, to
            stimulate the body's natural healing processes. It is rooted in the
            principles of traditional Chinese medicine and is based on the same
            concepts as acupuncture, including the flow of Qi (energy) and the
            balance of Yin and Yang. By pressing on these points, acupressure
            aims to relieve tension, reduce pain, improve circulation, and
            enhance overall health. It is often used to address conditions such
            as stress, headaches, muscle pain, and digestive issues. Unlike
            acupuncture, which uses needles, acupressure employs the fingers,
            thumbs, or palms to apply pressure, making it a non-invasive
            alternative. It can be performed by a trained practitioner or as a
            self-care technique, providing a simple and accessible way to
            promote well-being and relaxation.
          </span>

          <Link
            to="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
