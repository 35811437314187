import React from "react";
import root from "../assets/iconacu.jpg";
import implant from "../assets/iconmixo.jpg";
import digital from "../assets/iconcupping.jpg";
import dental from "../assets/iconpress.jpg";
import scaling from "../assets/iconPhysio.jpg";
import { Link } from "react-router-dom";

export default function OurServices() {
  return (
    <div className="md:px-0 px-4  flex justify-center items-center">
      <div className="  flex flex-col gap-8 md:w-10/12 w-full ">
        <div className=" flex justify-between items-center">
          <h1 className=" cursor-pointer  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Our Services
          </h1>
          <Link
            to="/allServices"
            className=" underline cursor-pointer hover:text-primary-350  duration-500"
          >
            View All Services
          </Link>
        </div>
        <p className=" md:text-[17px] text-[16px] text-gray-600">
          काठमाडौँ अकुपन्चर एण्ड फिजियोथेरापी क्लिनिक प्रा. लि. तथा मेक्सिबुसन
          चिकित्सा प्रणाली रोगहरूको रोकथाम र उपचारको लागि प्रयोग गरिने चिनियाँ
          औषधि विज्ञानको एउटा धेरै महत्वपूर्ण भाग हो । आजभन्दा करिब ५ हजार वर्ष
          पहिले चीनमा शुरु भएको यो चिकित्सा प्रणाली आज विश्वका विकसित र
          विकासोन्मुख सबै मुलुकहरूम अत्यन्त सफलताका साथ सञ्चालन भइराखेको छ। असल
          उपचार, शरीरलाई कुनै प्रतिकूल असर (साइड इफेक्ट) नहुनु, धेरे रोगहरूमा
          प्रयोग गर्न सकिने आदि गुणहरूले गर्दा यो चिकित्सा पद्धति आज विश्वभरि नै
          लोकप्रिय भइराखेको छ ।
        </p>
        <div className="card grid md:grid-cols-3 md:gap-14 gap-5 md:pr-0 pr-3">
          {services.map((service, index) => (
            <Link key={index} className=" group card1 w-full" to={service.path}>
              <img
                className=" h-14  w-14 rounded-md cursor-pointer object-cover"
                src={service.img}
                alt=""
              />
              <div className=" flex flex-col gap-3">
                <p className="small  mt-3 text-gray-700 font-semibold  group-hover:text-gray-100">
                  {service.name}
                </p>
                <span className=" small md:text-[17px] text-[15px] text-gray-600 group-hover:text-gray-200">
                  {service.desc}
                </span>
                <span className=" text-primary-400 text-[15px] font-semibold group-hover:text-gray-200 group-hover:underline">
                  {service.more}
                </span>
              </div>

              <div className="go-corner" to="/">
                <div className="go-arrow text-3xl">→</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

const services = [
  {
    img: root,
    name: "Acupuncture",
    desc: "Acupuncture is a traditional Chinese medicine practice that involves inserting ......",
    path: "/acupuncture",
    more: "Read More...",
  },
  {
    img: dental,
    name: "Acupressure",
    desc: "Acupressure is a therapeutic technique that involves applying manual .....",
    path: "/acupressure",
    more: "Read More...",
  },
  {
    img: implant,
    name: "Moxibustion",
    desc: "Moxibustion is a traditional Chinese medicine technique that involves ..... ",
    path: "/moxibustion",
    more: "Read More...",
  },
  {
    img: digital,
    name: "Cupping ",
    desc: "Cupping therapy is an ancient healing practice that involves placing .....",
    path: "/cupping",
    more: "Read More...",
  },

  {
    img: scaling,
    name: "Physiotherapy",
    desc: "Physiotherapy, also known as physical therapy, is a healthcare ......",
    path: "/physiotherapy",
    more: "Read More....",
  },
];
