import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import logo from "../assets/logo.png";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai"; // Import the close icon

const WhatsApp = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="fixed bottom-8 right-4 z-50">
      {/* Floating Action Button */}
      <button
        className="bg-green-500 text-white rounded-full w-12 h-12 flex items-center justify-center shadow-sm z-50 shadow-slate-700 hover:bg-green-600 focus:outline-none"
        onClick={togglePopup}
      >
        <FaWhatsapp className=" size-8" />
      </button>

      {/* Popup */}
      {showPopup && (
        <div className="bg-white border border-gray-200 shadow-sm shadow-green-400 rounded-lg p-5 max-w-md w-[15rem] absolute bottom-16 right-0 ">
          {/* Arrow indicator */}
          <div className="absolute top-full right-6 -mt-3">
            <div className="w-6 h-6 bg-white transform rotate-45 border border-gray-200 border-t-0 border-l-0 rounded-sm"></div>
          </div>

          <div className="flex justify-between items-center mb-4">
            <div className="flex flex-col items-start ml-2">
              <img src={logo} alt="Logo" className="w-10 h-10 mr-2  mb-4" />
              <div>
                <p className="font-semibold">Hi there !!</p>
                <p>How can we help you?</p>
              </div>
            </div>
            <button
              className="flex items-center justify-center text-gray-700 hover:text-gray-600 focus:outline-none"
              onClick={togglePopup}
            >
              <AiOutlineClose className="w-6 h-6" />
            </button>
          </div>
          <a
            href="https://wa.me/9851060999"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-between bg-primary-350 text-white duration-200 rounded-full py-2 px-4 mt-2 hover:bg-primary-300"
          >
            <p>Chat with us</p>
            <MdOutlineKeyboardArrowRight className="text-xl" />
          </a>
        </div>
      )}
    </div>
  );
};

export default WhatsApp;
